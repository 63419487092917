<template>
	<div
		:class="['panel', 'teaser',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row">
					<div class="col">
						<h2>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-lg-12">
					<div v-if="block.description" class="description" v-html="block.description" />
				</div>
			</div>

			<div v-if="isFooterVisible" class="panel_footer">
				<b-button
					v-scroll-to="'#header-registration-form'"
					variant="primary"
					@click="setPanelHeaderScenario()"
				>
					Запросить {{ block.custom_scenario_string || block.scenarios_ru_accusative[block.scenario] }}
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>

import panel from '~/mixins/panel';
import { EVENT_FORM_SCENARIOS } from '~/utils/dictionaries';

export default {
	name: 'AboutIssue',
	mixins: [panel],
	data() {
		return {
			EVENT_FORM_SCENARIOS,
		};
	},
	methods: {
		setPanelHeaderScenario(scenario) {
			if (
				typeof this.$parent.$refs['program-header'] === 'undefined'
				|| !(0 in this.$parent.$refs['program-header'])
			) {
				return;
			}

			this.$parent.$refs['program-header'].scenarioBlock = this.block.scenario;
			this.$parent.$refs['program-header'].scenarioBlockId = this.block.id;
		},
	},
};
</script>

<style lang="scss" scoped>
	// @import "~/assets/styles/components/home/panel-announces";
	@import "~/assets/styles/components/home/panel-teaser";
</style>
