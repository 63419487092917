var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'teaser',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-12"},[(_vm.block.description)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.block.description)}}):_vm._e()])]),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[_c('b-button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#header-registration-form'),expression:"'#header-registration-form'"}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setPanelHeaderScenario()}}},[_vm._v("\n\t\t\t\tЗапросить "+_vm._s(_vm.block.custom_scenario_string || _vm.block.scenarios_ru_accusative[_vm.block.scenario])+"\n\t\t\t")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }