import { render, staticRenderFns } from "./AboutIssue.vue?vue&type=template&id=2b7ff0ec&scoped=true"
import script from "./AboutIssue.vue?vue&type=script&lang=js"
export * from "./AboutIssue.vue?vue&type=script&lang=js"
import style0 from "./AboutIssue.vue?vue&type=style&index=0&id=2b7ff0ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7ff0ec",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports